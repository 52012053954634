@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Work+Sans&display=swap');

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Work Sans', sans-serif;
  color: #f4f4f4;
  background-color: #194350;
}

* {
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 0.7em;
  background-color: #34515a;
}

body::-webkit-scrollbar-thumb {
  outline: none;
  background-color: #9DBEB9;
  border-radius: 0.2em;
}

.alt-font {
  font-family: 'Pacifico', cursive;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.center {
  justify-content: center;
  align-items: center;
}

.left {
  justify-content: left;
  align-items: center;
}

.icon {
  color: #f4f4f4;
  max-height: 50px;
}